<template>
  <c-leaf class="education" tag="article">
    <header class="education-header">
      <b-row align-h="between">
        <b-col cols="auto">
          <b-badge v-if="tagText" class="education-tag" :variant="tagVariant">{{ tagText }}</b-badge>
        </b-col>

        <b-col cols="auto">
          <div class="education-state">
            <b-badge v-if="stateText" class="state-badge badge-outline" pill :variant="stateVariant">{{ stateText }}</b-badge>
            <div v-if="stateInfo" :class="['state-info', `text-${stateVariant}`]">{{ stateInfo }}</div>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="between">
        <b-col class="main-row">
          <div class="education-title">
            <slot name="title"/>
          </div>

          <div class="education-description">
            <slot name="description"/>
          </div>

          <div class="education-tags">
            <slot name="tags"/>
          </div>
        </b-col>

        <b-col :[$root.mobile.up.key]="'auto'" class="aside-row">
          <div v-if="$slots.action" class="education-action">
            <slot name="action"/>
          </div>
        </b-col>
      </b-row>
    </header>

    <slot/>
  </c-leaf>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const VARIANTS = [null, ''].concat(Object.keys(SCSS_VARIABLES.colors.theme))

export default {
  name: 'E-Learning',
  props: {
    tagText: {
      type: String,
      default: ''
    },
    tagVariant: {
      type: String,
      default: null,
      validator: value => VARIANTS.includes(value)
    },
    stateText: {
      type: String,
      default: ''
    },
    stateVariant: {
      type: String,
      default: null,
      validator: value => VARIANTS.includes(value)
    },
    stateInfo: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
$education-title-gap: $spacer !default;

$education-state-badge-gap: $spacer * 0.3 !default;
$education-state-badge-font-size: $font-size-sm !default;
$education-state-info-font-size: $font-size-sm !default;
$education-state-info-font-style: italic !default;

$education-description-gap: $education-title-gap !default;

$education-tags-gap: $education-state-badge-gap !default;

$education-mobile-breakpoint: $mobile-breakpoint !default;

$education-mobile-asiderow-gap: $spacer !default;

.education {
  .education-header {
    .main-row {}

    .aside-row {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .education-tag {
      margin-bottom: $education-tags-gap;
    }

    .education-title {
      margin-bottom: $education-title-gap;

      * {
        margin-bottom: 0;
      }
    }

    .education-state {
      text-align: right;

      .state-badge {
        margin-bottom: $education-state-badge-gap;
        font-size: $education-state-badge-font-size;
      }

      .state-info {
        font-size: $education-state-info-font-size;
        font-style: $education-state-info-font-style;
      }
    }

    .education-description {
      margin-bottom: $education-description-gap;
    }

    .education-tags {
      display: flex;
      flex-wrap: wrap;
      margin: $education-tags-gap * -0.5;

      .badge {
        margin: $education-tags-gap * 0.5;
      }
    }

    .education-action {}
  }

  > *:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-down($education-mobile-breakpoint) {
    .education-header {
      .main-row {
        + .aside-row {
          margin-top: $education-mobile-asiderow-gap;
        }
      }
    }
  }
}
</style>
